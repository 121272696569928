import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/beadswapfrontend/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "jsbi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#jsbi",
        "aria-label": "jsbi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`JSBI`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { JSBI } from '@beadswap/sdk'
// import JSBI from 'jsbi'
`}</code></pre>
    <p>{`The default export from `}<a parentName="p" {...{
        "href": "https://github.com/GoogleChromeLabs/jsbi"
      }}>{`jsbi`}</a>{`.`}</p>
    <h1 {...{
      "id": "bigintish",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#bigintish",
        "aria-label": "bigintish permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`BigintIsh`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { BigintIsh } from '@beadswap/sdk'
// type BigintIsh = JSBI | bigint | string
`}</code></pre>
    <p>{`A union type comprised of all types that can be cast to a JSBI instance.`}</p>
    <h1 {...{
      "id": "chainid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#chainid",
        "aria-label": "chainid permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ChainId`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId } from '@beadswap/sdk'
// enum ChainId {
//   MAINNET = 56,
//   TESTNET = 97
// }
`}</code></pre>
    <p>{`A enum denominating supported chain IDs.`}</p>
    <h1 {...{
      "id": "tradetype",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tradetype",
        "aria-label": "tradetype permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TradeType`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { TradeType } from '@beadswap/sdk'
// enum TradeType {
//   EXACT_INPUT,
//   EXACT_OUTPUT
// }
`}</code></pre>
    <p>{`A enum denominating supported trade types.`}</p>
    <h1 {...{
      "id": "rounding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#rounding",
        "aria-label": "rounding permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rounding`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { Rounding } from '@beadswap/sdk'
// enum Rounding {
//   ROUND_DOWN,
//   ROUND_HALF_UP,
//   ROUND_UP
// }
`}</code></pre>
    <p>{`A enum denominating supported rounding options.`}</p>
    <h1 {...{
      "id": "factory_address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#factory_address",
        "aria-label": "factory_address permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FACTORY_ADDRESS`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { FACTORY_ADDRESS } from '@beadswap/sdk'
`}</code></pre>
    <p>{`The `}<Link to='/docs/v1/smart-contracts/factory/#address' mdxType="Link">{`factory address`}</Link>{`.`}</p>
    <h1 {...{
      "id": "init_code_hash",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#init_code_hash",
        "aria-label": "init_code_hash permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INIT_CODE_HASH`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { INIT_CODE_HASH } from '@beadswap/sdk'
`}</code></pre>
    <p>{`See `}<Link to='/docs/v1/smart-contracts/factory/#address' mdxType="Link">{`Pair Addresses`}</Link>{`.`}</p>
    <h1 {...{
      "id": "minimum_liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#minimum_liquidity",
        "aria-label": "minimum_liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MINIMUM_LIQUIDITY`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { MINIMUM_LIQUIDITY } from '@beadswap/sdk'
`}</code></pre>
    <p>{`See `}<Link to='/docs/v1/protocol-overview/smart-contracts/#minimum-liquidity' mdxType="Link">{`Minimum Liquidity`}</Link>{`.`}</p>
    <h1 {...{
      "id": "insufficientreserveserror",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#insufficientreserveserror",
        "aria-label": "insufficientreserveserror permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InsufficientReservesError`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { InsufficientReservesError } from '@beadswap/sdk'
`}</code></pre>
    <h1 {...{
      "id": "insufficientinputamounterror",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#insufficientinputamounterror",
        "aria-label": "insufficientinputamounterror permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InsufficientInputAmountError`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { InsufficientInputAmountError } from '@beadswap/sdk'
`}</code></pre>
    <h1 {...{
      "id": "wbnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#wbnb",
        "aria-label": "wbnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WBNB`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { WBNB } from '@beadswap/sdk'
`}</code></pre>
    <p>{`An object whose values are `}<Link to='/docs/v1/smart-contracts/router02/#weth' mdxType="Link">{`WBNB`}</Link>{` `}<Link to='/docs/v1/SDK/token' mdxType="Link">{`Token`}</Link>{` instances, indexed by `}<a parentName="p" {...{
        "href": "#chainid"
      }}>{`ChainId`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      